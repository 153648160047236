import React from 'react';
import bglessLogo from '../../images/bglessLogo.png';
import './NoData.scss';

function NoData(props) {
    return (
        <div className='noDataPage'>
            <div className='noDataIconNInfo'>
                {/* <img src={bglessLogo} alt="로딩중" /> */}
                <div className='pArea'>
                    <p>NoData</p>
                    {/* <p>잠시만 기다려 주세요.</p> */}
                </div>
            </div>
        </div>
    );
}

export default NoData;