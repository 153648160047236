import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { API_URL } from '../../../config/constants';
import arrowIconImg from '../../../images/filledArrowIcon.png';
import CancelModal from './CancelModal';
import FadeOutNoti from '../../components/FadeOutNoti';
import moment from 'moment';
import 'moment/locale/ko';
import Select from 'react-select';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import useAsync from '../../../hooks/useAsync';
import { useSearchParams } from 'react-router-dom';
import DetailDataTRs from './components/DetailDataTRs';

function DataDetail(props) {
    async function getNormalRank() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getUserRankByRole`, { role: 'normal' });
        return response.data;
    }
    const { modelTypeArr, dataArr, setDataArr } = useOutletContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const sumDsId = searchParams.get("sumDsId");
    const dataId = searchParams.get("dataId");
    const navigate = useNavigate();
    const { userRank } = useSelector((state) => state.session);

    const [formData, setFormData] = useState({
        time: "2000-01-01T00:00:00.000Z",
        count: 0,
        decision: 'OK',
        modelId: 0,
        model: {
            modelType: '',
            modelName: '',
            modelNo: '',
        },
        materialYS: 0.0,
        materialUTS: 0.0,
        materialEU: 0.0,
        peak: 0.0,
        abnormalPattern: 'OK',
        sizeLeft: 0.0,
        sizeRight: 0.0,
        ngFactor: '',
        comment: '',
    });
    const [realData, setRealData] = useState({
        time: "2000-01-01T00:00:00.000Z",
        count: 0,
        decision: 'OK',
        modelId: 0,
        model: {
            modelType: '',
            modelName: '',
            modelNo: '',
        },
        materialYS: 0.0,
        materialUTS: 0.0,
        materialEU: 0.0,
        peak: 0.0,
        abnormalPattern: 'OK',
        sizeLeft: 0.0,
        sizeRight: 0.0,
        ngFactor: '',
        comment: '',
    });
    const [dateTime, setDateTime] = useState(new Date());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modelTypes, setModelTypes] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [modelNos, setModelNos] = useState([]);
    const [sumDs, setSumDs] = useState({});
    const [showNoti, setShowNoti] = useState(false);



    function onSubmit(e) {
        e.preventDefault();
        const { dataId, ...rest } = formData;
        if (formData.modelId === 0) return false;

        axios.post(`${API_URL}/android/updateData`, {
            dataId: dataId, newValue: {
                ...rest,
                time: moment(dateTime),
            }
        }).then(res => {
            const copiedArr = [...dataArr];
            const filteredArr = copiedArr.filter(item => item.dataId !== dataId);
            setDataArr([...filteredArr, res.data]);
            setShowNoti(true);
            setTimeout(() => {
                window.location.href = '/home/data/sumData'
            }, 2500);
        });
    };

    async function onChange(e) {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function onOpenCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(true);
    };

    function onCloseCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(false);
    };

    function onCancel(e) {
        e.preventDefault();
        navigate('/home/data/sumData');
    };

    async function modelInit(modelId) {
        setModelTypes([...modelTypeArr.map(item => ({ label: item, value: item }))]);
        await axios.post(`${API_URL}/android/getModelById`, { modelId }).then(res => {
            const { modelNames, modelNos } = res.data;
            setModelNames([...modelNames.map(item => ({ label: item, value: item }))]);
            setModelNos([...modelNos.map(item => ({ label: item.modelNo, value: item }))]);
        }).catch(e => console.error(e));
    }
    const decisionOpt = [
        { label: 'NG', value: 'NG' },
        { label: 'OK', value: 'OK' },
    ];

    const ngFactorOpt = [
        { label: '없음', value: '' },
        { label: '원자재 - YS', value: '원자재 - YS' },
        { label: '원자재 - UTS', value: '원자재 - UTS' },
        { label: '하중 - Peak', value: '하중 - Peak' },
        { label: '하중 - 이상패턴', value: '하중 - 이상패턴' },
        { label: '치수 - 좌', value: '치수 - 좌' },
        { label: '치수 - 우', value: '치수 - 우' },
    ]

    async function getOneData() {
        await axios.post(`${API_URL}/monitoring-manage/getSumData`, { sumDsId, dataId }).then(res => {
            const resSuccess = res.data.success;
            const resData = res.data.data
            console.log(resData);
            if (resSuccess) {
                setSumDs({...res.data.sumDs});
                modelInit(resData.modelId);
                setFormData({ ...resData, comment: resData.comment ?? '', ngFactor: resData.ngFactor ?? '' });
                setRealData({ ...resData })
                setDateTime(new Date(resData.time));
            } else {
                navigate('/home/data/sumData');
            }
        }).catch(err => console.error(err));
    }


    useEffect(() => {
        // if (data) {
        //     modelInit(data.modelId);
        //     setFormData({ ...data });
        //     setDateTime(new Date(data.time))
        // } else {
        //     navigate('/home/data/sumData');
        // }
        getOneData();
    }, []);

    const state = useAsync(getNormalRank);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return <div>실패</div>;

    return (
        <React.Fragment>
            <div className='dataEditArea'>
                <p className='path'>데이터 관리<span className='below'>{'>'}</span>공정 요약<span className='below'>{'>'}</span>데이터 상세</p>
                <h2 className='pageTitle'>데이터 상세</h2>
                <div className='pageContent'>
                    <form onSubmit={onSubmit} >
                        <table>
                            <tbody>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor="time">시간</label></td>
                                    <td className='inputTd' >
                                        {result.rank === userRank ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') :
                                            <CustomDatePicker
                                                data={dateTime} setData={setDateTime}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor="count">카운트</label></td>
                                    <td className='inputTd' >
                                        <input id="count" name="count" type="number" value={formData.count} onChange={onChange} readOnly={result.rank === userRank} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='decision'>판정</label></td>
                                    <td className='inputTd' >
                                        {
                                            result.rank === userRank
                                                ? `${decisionOpt.find(el => el.value === formData.decision).value}`
                                                : <div className='selectArea'>
                                                    <Select
                                                        options={decisionOpt}
                                                        value={decisionOpt.find(el => el.value === formData.decision)}
                                                        onChange={sel => setFormData({ ...formData, decision: sel.value })}
                                                        isSearchable={false}
                                                        classNames={{
                                                            control: (state) => 'control',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />

                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelType'>차종</label></td>
                                    <td className='inputTd' >
                                        {
                                            result.rank === userRank
                                                ? `${modelTypes.find(el => el.value === formData.model.modelType)?.value}`
                                                :
                                                <div className='selectArea'>
                                                    <Select
                                                        options={modelTypes}
                                                        value={modelTypes.find(el => el.value === formData.model.modelType)}
                                                        onChange={async sel => {
                                                            await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                                                setModelNames([...res.data.map(item => ({ label: item, value: item }))]);
                                                                // modelNamesDropdown.current.value = "";
                                                                setModelNos([]);
                                                                // modelNosDropdown.current.value = "";
                                                                setFormData({
                                                                    ...formData,
                                                                    modelId: 0,
                                                                    model: {
                                                                        modelType: sel.value,
                                                                        modelName: '',
                                                                        modelNo: '',
                                                                    }
                                                                });
                                                            }).catch(err => console.error(err));
                                                        }}
                                                        isSearchable={false}
                                                        classNames={{
                                                            control: (state) => 'control large',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelName'>품명</label></td>
                                    <td className='inputTd' >
                                        {
                                            result.rank === userRank
                                                ? `${modelNames.find(el => el.value === formData.model.modelName)?.value}`
                                                :
                                                <div className='selectArea'>

                                                    <Select
                                                        options={modelNames}
                                                        value={formData.model.modelName === "" ? { label: '선택' } : modelNames.find(el => el.value === formData.model.modelName)}
                                                        onChange={async sel => {
                                                            await axios.post(`${API_URL}/android/getModelNos`, { modelType: formData.model.modelType, modelName: sel.value }).then(res => {
                                                                setModelNos([...res.data.map(item => ({ label: item.modelNo, value: item }))]);
                                                                // modelNosDropdown.current.value = "";
                                                                setFormData({
                                                                    ...formData,
                                                                    modelId: 0,
                                                                    model: {
                                                                        ...formData.model,
                                                                        modelName: sel.value,
                                                                        modelNo: '',
                                                                    }
                                                                });
                                                            }).catch(err => console.error(err));
                                                        }}
                                                        isSearchable={false}
                                                        isDisabled={modelNames.length === 0}
                                                        classNames={{
                                                            control: (state) => 'control large',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelNo'>품번</label></td>
                                    <td className='inputTd' >
                                        {
                                            result.rank === userRank
                                                ? `${modelNos.find(el => el.value.modelNo === formData.model.modelNo)?.value.modelNo}`
                                                :
                                                <div className='selectArea'>
                                                    <Select
                                                        options={modelNos}
                                                        value={formData.model.modelNo === "" ? { label: '선택' } : modelNos.find(el => el.value.modelNo === formData.model.modelNo)}
                                                        onChange={sel => {
                                                            setFormData({
                                                                ...formData,
                                                                modelId: sel.value.modelId,
                                                                model: {
                                                                    ...formData.model,
                                                                    modelNo: sel.value.modelNo,
                                                                }
                                                            })
                                                        }}
                                                        isSearchable={false}
                                                        isDisabled={modelNos.length === 0}
                                                        classNames={{
                                                            control: (state) => 'control large',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <DetailDataTRs
                                    sumDs={sumDs}
                                    data={realData}
                                    isNormalUser={result.rank === userRank}
                                    onChange={onChange}
                                    onChangeBool={(name, sel) => setFormData({...formData, [name]: sel.value})}
                                    formData={formData}
                                />
                                
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='ngFactor'>NG 요인</label></td>
                                    <td className='inputTd' >
                                        {
                                            result.rank === userRank
                                                ? `${ngFactorOpt.find(el => el.value === formData.ngFactor).value === '' ? '없음' : ngFactorOpt.find(el => el.value === formData.ngFactor).value}`
                                                :
                                                <div className='selectArea'>
                                                    <Select
                                                        options={ngFactorOpt}
                                                        value={ngFactorOpt.find(el => el.value === formData.ngFactor)}
                                                        onChange={sel => setFormData({ ...formData, ngFactor: sel.value })}
                                                        isSearchable={false}
                                                        maxMenuHeight={'140px'}
                                                        classNames={{
                                                            control: (state) => 'control',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='comment'>코멘트</label></td>
                                    <td className='inputTd' >
                                        <div className='commentArea'>
                                            <textarea id='comment' name='comment' value={formData.comment} onChange={onChange} readOnly={result.rank === userRank} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='formBtnArea'>
                            {
                                result.rank === userRank
                                    ?
                                    <button className='backBtn' onClick={e => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}>뒤로 가기</button>
                                    :
                                    <React.Fragment>
                                        <input className='submitBtn' type='submit' value="확인" />
                                        <button className='cancelBtn' onClick={onOpenCancelModal}>취소</button>
                                    </React.Fragment>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <CancelModal
                isOpen={modalIsOpen}
                onRequestClose={onCloseCancelModal}
                onSubmit={onCancel}
            />
            <FadeOutNoti
                showNoti={showNoti}
                onClose={() => {
                    setShowNoti(false);
                }}
                msg={"변경 사항이 저장되었습니다."}
                bg={true}
            />
        </React.Fragment>
    );
}

export default DataDetail;