import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState, Suspense, useTransition } from 'react';
import { API_URL } from '../../../config/constants';
import './Monitoring.scss';
import CustomLineChart from '../../components/CustomLineChart';
import { CSVLink } from 'react-csv';
import DataListMenu from './DataListMenu';
import MonitTable from './MonitTable';
import csvSaveIcon from '../../../images/csvSave.png';
import { Scrollbars } from 'rc-scrollbars';
import { useBlocker, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import YouWereEditing from '../../components/YouWereEditing';
import editModeIcon from '../../../images/editDS.png';
import Loading from '../../components/Loading';
import NoData from '../../components/NoData';
import ChkDelModal from '../../components/ChkDelModal';

// const MonitTable = React.lazy(() => import('./MonitTable'));

function RealTimeMonitoring(props) {
    // 현재 로그인한 유저가 일반 사용자인지 여부
    const { userIsNormal } = useOutletContext();
    const navigate = useNavigate();

    // 공정 요약에서 '상세' 버튼으로 진입했을 경우 데이터 요청에 활용되는 쿼리스트링
    const [searchParams, setSearchParams] = useSearchParams();
    const qsName = searchParams.get("name");
    const qsDataId = searchParams.get("dataId");

    const [isPending, startTransition] = useTransition();

    // CSV 저장 시 파일 명으로 활용할 접두어
    const companyInitial = 'K';
    const sensorTypeInitial = 'D';
    // 추후 계정 정보에서 company 컬럼 데이터 받아오도록 수정要
    // company 정보를 기준으로 rawData 테이블을 선별해 받아옴
    const company = "세동산업";

    const [dataStructures, setDataStructures] = useState([]);   // 라디오 버튼 및 각종 세부 데이터 구조 관련 정보
    const [curPageData, setCurPageData] = useState({}); // 현재 세부데이터 영역에서 출력하고 있는 데이터

    const [lastId, setLastId] = useState(null); // getMoreData에서 불러올 세부데이터 시작점을 전달하기 위한 마지막 세부데이터 id
    const [noMoreData, setNoMoreData] = useState(false);    // 더이상 DB에 getMoreData로 불러올 과거 세부데이터가 없을 때 true

    const [selSensorIdx, setSelSensorIdx] = useState(-1);   // 상단 라디오 버튼 선택 상태
    const [selDataIdx, setSelDataIdx] = useState(-1);   // 좌측 세부데이터 목록 선택 상태

    // 수정모드 관련 상태
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState([]);
    const [changed, setChanged] = useState(false);

    const [chkDelModalIsOpen, setChkDelModalIsOpen] = useState(false);  // 삭제 여부 재확인 팝업모달 출력 여부
    const [isLoading, setIsLoading] = useState(true);   //  API서버에서 데이터 요청 중일 때 대신 출력할 로딩 페이지 출력 여부
    const [pageData, setPageData] = useState(true);     // 페이지 데이터 없을 경우 로딩 페이지로 안넘어가고 NoData 페이지 출력 여부

    async function getInitMonit() {
        // 페이지가 처음 마운트될 때 데이터 구조 가져옴
        await axios.post(`${API_URL}/monitoring-manage/getMonitDataStructures`, { company: '세동산업', name: qsName, dataId: qsDataId }).then((res) => {
            const dataStructureArr = res.data["dataStructures"];
            if(dataStructureArr.length === 0){
                setIsLoading(false);
                setPageData(false);
                return;
            }
            let nameKr;
            if (dataStructureArr.length > 0) {
                switch (qsName) {
                    case 'material':
                        nameKr = '원자재';
                        break;
                    case 'load':
                        nameKr = '하중';
                        break;
                    case 'size':
                        nameKr = '치수';
                        break;
                }
                for(let i = 0; i < dataStructureArr.length; i++){
                    if( qsName && (nameKr === dataStructureArr[i].class) ){
                        setSelSensorIdx(i);
                    }
                    else{
                        setSelSensorIdx(0);
                    }
                }
                setDataStructures(dataStructureArr)
            }
        })
    }

    async function getInitMonitData() {
        // 해당하는 라디오 버튼에 대한 세부 데이터 요청 
        await axios.post(`${API_URL}/monitoring-manage/getInitMonit`, { company: '세동산업', name: qsName, dataId: qsDataId, dataStructures: dataStructures, selSensorIdx: selSensorIdx }).then((res) => {
            
            const resInitData = res.data;
            console.log(resInitData)
            console.log(dataStructures)
            const convResData = resInitData["datas"].map(data => {
                const dsData = data.dataStructure;
                const monData = data.monitData;
                const convMonData = monData.map(mon => {
                    const dataStr = mon.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                    const dataArr = dataStr.split(';');
                    dataArr.pop();
                    const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                    return ({ ...mon, data: arrayConvertedDataArr })
                });
                const dataNameArr = dsData.rawName;
                const dimensionArr = dsData.rawDim;
                const sumDsId = dsData.sumDsId;
                return ({
                    ...data,
                    monitData: convMonData,
                    rawNo: dsData.rawNo,
                    rawName: dataNameArr,
                    dimension: dimensionArr,
                    sumDataId: sumDsId,
                });
            });
            const lastMdId = convResData[0]["monitData"].length > 0 ? convResData[0]["monitData"][convResData[0]["monitData"].length - 1].dataId : -1;
            if (resInitData["selectedInfo"] === undefined) {
                if (convResData[0].monitData.length > 0) startTransition(() => setSelDataIdx(0));
                console.log(convResData[0])
                setCurPageData(prev => convResData[0]);
                setLastId(prev => lastMdId);
                setNoMoreData(convResData[0].end);
                setIsLoading(false)
            } else {
                setSelSensorIdx(resInitData["selectedInfo"]["selectedDSIdx"]);
                setSelDataIdx(resInitData["selectedInfo"]["selectedDataIdx"]);
                console.log(resInitData)
                setCurPageData({ ...convResData[0] });
                console.log(convResData)
                setLastId(prev => convResData[0]["monitData"][convResData[0]["monitData"].length - 1].dataId);
                setNoMoreData(convResData[0].end);
                setIsLoading(false)
            }

        });


    }

    // 라디오 버튼 선택 이벤트
    const onChangeSensorType = (e) => {
        e.preventDefault();
        console.log('타입 변경')
        const wdIdx = parseInt(e.target.value);
        setSelSensorIdx(wdIdx);
    };

    // 세부데이터 삭제 버튼 이벤트
    const onDeleteData = async (e) => {
        e.preventDefault();
        const name = dataStructures[selSensorIdx]["name"];
        const dataId = curPageData["monitData"][selDataIdx]["dataId"];
        console.log(name)
        console.log(dataId)
        await axios.post(`${API_URL}/monitoring-manage/deleteMonit`, { setName: name, dataId: dataId, name: curPageData["dataStructure"]["name"] }).then(res => {
            const { success } = res.data;
            if (success) {
                if (curPageData["monitData"].length - 1 === 0) {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    setSelDataIdx(-1);
                    setChkDelModalIsOpen(false);
                } else if (selDataIdx === curPageData["monitData"].length - 1) {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    setSelDataIdx(prev => prev - 1);
                    setChkDelModalIsOpen(false);
                } else {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    setSelDataIdx(prev => prev + 1);
                    setChkDelModalIsOpen(false);
                }
            } else {
                // 시도가 성공하지 않음을 알림
            }
        }).catch(err => console.error(err));
    }

    // 수정모드에서 각 input의 onChange 이벤트
    const onChangeInput = (e, index, key) => {
        const copiedArr = [...formData];
        copiedArr[index][key] = parseFloat(e.target.value);
        setFormData([...copiedArr]);
        setChanged(true);
    }

    // 최신 데이터 
    const getPrevData = async e => {
        try {
            console.log("최신데이터")
            const res = await axios.post(`${API_URL}/monitoring-manage/getPrevMonit`, {
                headId: curPageData['monitData'][0].dataId, company, isAbout: dataStructures[selSensorIdx]["name"]
            });
            const monitDatas = res.data.monitData;
            if (monitDatas.length > 0) {
                const convertedData = monitDatas.map(item => {
                    const dataStr = item.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                    const dataArr = dataStr.split(';');
                    dataArr.pop();
                    const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                    return ({
                        ...item,
                        data: arrayConvertedDataArr,
                    });
                })
                const copiedArr = [curPageData];
                console.log(copiedArr[0]['monitData'])
                copiedArr[0]['monitData'] = [...convertedData, ...copiedArr[0]['monitData']];
                setCurPageData(prevObj => ({
                    ...prevObj,
                    monitData: [...copiedArr[0]["monitData"]]
                }));
                setNoMoreData(prev => res.data.end ?? prev);
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getData = useCallback(async (isAbout) => {
        console.log('겟 데이터 실행')
        // setPageLoading(true);
        try {
            const res = await axios.post(`${API_URL}/monitoring-manage/getMonit`, { lastId: lastId, company: company, isAbout: isAbout });
            const monitDatas = res.data.monitData;
            // monitDatas = [..., { dataId, dDsId, data, date }]
            const convertedData = monitDatas.map(item => {
                const dataStr = item.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                const dataArr = dataStr.split(';');
                dataArr.pop();
                const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                return ({
                    ...item,
                    data: arrayConvertedDataArr,
                });
            })
            console.log(curPageData.monitData)
            console.log(convertedData)

            // console.log('실행 완료')
            const copiedArr = [curPageData];
            console.log(copiedArr)
            copiedArr[0]['monitData'] = [...copiedArr[0]['monitData'], ...convertedData];
            console.log(copiedArr[0]['monitData'])
            
            setCurPageData(prevObj => ({
                ...prevObj,
                monitData: [...copiedArr[0]["monitData"]]
            }))
            setLastId(convertedData[convertedData.length - 1].dataId);
            setNoMoreData(res.data.end);

            console.log('실행 완료')
        } catch (e) {
            console.error(e);
        }
    }, [lastId, curPageData]);

    // 수정 버튼 이벤트
    const onSubmitEdit = async e => {
        e.preventDefault();
        const selData = { ...curPageData["monitData"][selDataIdx] };
        // formData에 상태관리 중이던 값들을 변환, NaN 오류 방지
        const sortedFormData = formData.map(item => {
            const retArr = [];
            const convTime = Object.is(parseFloat(item.time), NaN) ? 0.0 : Number.isInteger(parseFloat(item.time)) ? parseFloat(item.time).toFixed(1) : parseFloat(item.time);
            const convData1 = Object.is(parseFloat(item.data1), NaN) ? 0.0 : Number.isInteger(parseFloat(item.data1)) ? parseFloat(item.data1).toFixed(1) : parseFloat(item.data1);
            retArr.push(convTime);
            retArr.push(convData1);
            if (item.data2) {
                const convData2 = Object.is(parseFloat(item.data2), NaN) ? 0.0 : Number.isInteger(parseFloat(item.data2)) ? parseFloat(item.data2).toFixed(1) : parseFloat(item.data2);
                retArr.push(convData2);
            }
            return retArr;
        });

        // 'time' 열을 기준으로 오름차순 정렬
        sortedFormData.sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

        let csvStr = '';
        for (const item of sortedFormData) {
            const dataStr = `${item[0]},${item[1]}${item[2] ? (',' + item[2]) : ''};`
            csvStr = csvStr + dataStr;
        };
        const reqData = {
            data: csvStr,
        }



        await axios.post(`${API_URL}/monitoring-manage/updateMonit`, { data: reqData, dataId: selData.dataId, isAbout: dataStructures[selSensorIdx]["name"], }).then(res => {
            const { success, data } = res.data;
            if (success) {
                updateOneData(data);
            } else {
                console.log(res.data.error);
                window.alert('업데이트에 실패했습니다. 잠시 후 다시 시도해 주세요.');
            }
        }).catch(err => { console.error(err) })

    };

    // 취소 버튼 이벤트
    const onReset = e => {
        const selData = { ...curPageData["monitData"][selDataIdx] };
        setFormData(selData.data.map((el, idx) => {
            const retObj = { "id": idx };
            for (let i = 0; i < el.length; i++) {
                if (i === 0) {
                    retObj["time"] = parseFloat(el[i]);
                } else {
                    retObj[`data${i}`] = parseFloat(el[i]);
                };
            };
            return (retObj);
        }));
        setChanged(false);
        setIsEditMode(false);
    };
    const updateOneData = (propData) => {
        const { updatedData, dataStructure } = propData;
        const { dataId, data } = updatedData;
        const monitData = [...curPageData['monitData']];
        const dataIdx = monitData.findIndex(obj => obj.dataId === dataId);
        const dataArr = data.split(';');
        dataArr.pop();
        const arrayConvertedDataArr = dataArr.map(el => el.split(','));

        const convertedData = {
            ...updatedData, data: arrayConvertedDataArr,
        };
    }

    // CSV 저장 시도 시 CSV 형태로 변환하는 함수
    function makeCsvData() {
        const selData = { ...curPageData["monitData"][selDataIdx] };
        const dataNamesStr = dataStructures[selSensorIdx].rawName;
        const labelArr = ["time", ...dataNamesStr];
        const dataArr = selData.data;
        const mergedArr = [labelArr, ...dataArr];
        return mergedArr;
    }

    // 공정 요약 → 버튼 클릭 이벤트
    const onClickChkSumData = e => {
        e.preventDefault();
        console.log(curPageData["dataStructure"]);
        navigate(`/home/data/sumData/detail?sumDsId=${curPageData["dataStructure"]["sumDsId"]}&dataId=${curPageData["monitData"][selDataIdx]["sumDataId"]}`);
    };

    // selData에 변동이 있으면 input에 각 데이터를 배치
    useEffect(() => {
        // setTableLoading(true);
        const selData = curPageData && selDataIdx !== -1 ? { ...curPageData["monitData"][selDataIdx] } : null;
        const convData = selData ? selData.data.map((el, idx) => {
            const retObj = { "id": idx };
            for (let i = 0; i < el.length; i++) {
                if (i === 0) {
                    retObj["time"] = parseFloat(el[i]);
                } else {
                    retObj[`data${i}`] = parseFloat(el[i]);
                };
            };
            return (retObj);
        }) : [];
        setFormData([...convData]);
        console.log(formData)
    }, [curPageData, selSensorIdx, selDataIdx]);
    // 수정 도중 페이지를 전환할 때 재확인
    const [blockPopup, setBlockPopup] = useState(false);    // 수정 중임을 알리는 재확인 팝업 출력 여부
    let blocker = useBlocker(({ currentLocation, nextLocation }) =>
        changed &&  // 수정 상태이고
        currentLocation.pathname !== nextLocation.pathname);    // 이동하려는 pathname이 현재와 다를 때
    useEffect(() => {
        if (blocker.state === "blocked") {  // blocker가 발동하면
            setBlockPopup(true);    //팝업 출력
        } else {
            setBlockPopup(false);
        }
    }, [blocker.state]);


    // 페이지가 처음 마운트될 때 getInitMonit() 실행
    useEffect(() => {
        const initMonit = async () => {
            await getInitMonit();
        };
        initMonit();
    }, []);

    async function updateSelSensorIdx() {
        if (selSensorIdx >= 0) {
            await getInitMonitData();
        }
    }
    useEffect(() => {
        updateSelSensorIdx();
    }, [selSensorIdx]); // selSensorIdx가 변경될 때 실행

    return (
        pageData === false ? <NoData /> :
        isLoading ? <Loading /> :
            <React.Fragment>
                <div className='monitoringPage'>
                    <p className='path'>데이터 관리<span className='below'>{'>'}</span>세부 데이터</p>
                    <h2 className='pageTitle'>세부 데이터</h2>
                    <div className='btnsArea'>
                        <Scrollbars style={{ width: '100%' }} >
                            <div className='scrollArea' style={{ minWidth: `${200 * dataStructures.length}px` }}>
                                {
                                    dataStructures.map((item, idx, arr) => {
                                        return (
                                            <div className='sensorOnOffArea' key={idx}>
                                                <p>
                                                    <input className='sensorTypeRadio' type='radio' name='sensorType' id={item.name} value={idx} onChange={onChangeSensorType} checked={selSensorIdx === idx} />
                                                    <label htmlFor={item.name} className='sensorTitle'><span className={selSensorIdx === idx ? 'radioVisual on' : 'radioVisual off'} />{item.name}</label>
                                                </p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    <div className='monitArea'>
                        {/* 좌측 데이터 목록 컴포넌트 */}
                        <DataListMenu
                            monitData={selSensorIdx >= 0 ? curPageData["monitData"] : []}
                            selSensorIdx={selSensorIdx}
                            selDataIdx={selDataIdx}
                            setSelDataIdx={setSelDataIdx}
                            getPrevData={e => {
                                e.preventDefault();
                                console.log('이전 데이터 추가');
                                getPrevData(e);
                            }}
                            getMoreData={e => {
                                e.preventDefault();
                                console.log('새 데이터 추가');
                                getData(dataStructures[selSensorIdx]["name"]);
                            }}
                            isLastData={noMoreData}
                        />
                        <div className='monitPrintArea'>
                            {
                                selDataIdx === -1 ? null :
                                    <div className='monitContainer'>
                                        <div className='monitTblDiv'>
                                            <div className='monitTblTop'>
                                                <div className='sumDataInfoDiv'>
                                                    <SumDataInfo curPageMonitData={curPageData["monitData"][selDataIdx]} sumDsId= {curPageData["dataStructure"]["sumDsId"]}/>
                                                    {/* <button className='toSumData' onClick={onClickChkSumData} disabled={!curPageData["monitData"][selDataIdx]["sumData"]["dataId"]}> */}
                                                    <button className='toSumData' onClick={onClickChkSumData} >
                                                        <span>공정 요약</span>
                                                        <span className='icon'>
                                                            →
                                                        </span>
                                                    </button> 
                                                </div>
                                                <div className='btnsArea'>
                                                    <div className={!isEditMode ? 'viewModeBtnsArea visible' : 'viewModeBtnsArea invisible'}>
                                                        <CSVLink className='saveCSVBtn' data={makeCsvData()} filename={`${companyInitial}${sensorTypeInitial}${curPageData["monitData"][selDataIdx]["date"]}`}>
                                                            <img src={csvSaveIcon} alt='csv 저장' />
                                                            <span>CSV 저장</span>
                                                        </CSVLink>
                                                        <button className='deleteDataBtn' onClick={e => { e.preventDefault(); setChkDelModalIsOpen(true); }}>
                                                            데이터 삭제
                                                        </button>
                                                        <button className={isEditMode ? 'editBtn editModeBtn activated' : 'editBtn editModeBtn deactivated'} onClick={e => {
                                                            e.preventDefault();
                                                            setIsEditMode(!isEditMode);
                                                        }} disabled={userIsNormal}><img src={editModeIcon} /></button>
                                                    </div>
                                                    <div className={isEditMode ? 'editModeBtnsArea visible' : 'editModeBtnsArea invisible'}>
                                                        <button className='editBtn' onClick={onSubmitEdit} disabled={!changed}>저장</button>
                                                        <button className='cancelBtn' onClick={onReset} >취소</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='monitTblArea'>
                                                {/* 우상단 데이터 테이블 컴포넌트 */}
                                                <MonitTable dataStructures={dataStructures} selSensorIdx={selSensorIdx} selDataIdx={selDataIdx} formData={formData} onChangeInput={onChangeInput} userIsNormal={userIsNormal} isEditMode={isEditMode}
                                                // loading={tableLoading} setLoading={setTableLoading}
                                                />
                                            </div>
                                        </div>
                                        <div className='chartArea'>
                                            <div className='lineChartArea'>
                                                {/* 차트 컴포넌트 */}
                                                <CustomLineChart
                                                    data={formData}
                                                    dsData={curPageData.dataStructure}
                                                />
                                                <ChartInfo data={{ ...curPageData["monitData"][selDataIdx], data: formData }} dataStructure={curPageData.dataStructure} />
                                                <div className='legendDiv'>
                                                    <div className='legend'>
                                                        <div className='stroke' style={{ backgroundColor: '#0A2B83' }} />
                                                        <p>{curPageData.dataStructure.rawName[0]}</p>
                                                    </div>
                                                    <div className={curPageData.dataStructure.name.length < 2 ? 'legend invisible' : 'legend'}>
                                                        <div className='stroke' style={{ backgroundColor: '#45BFCE' }} />
                                                        <p>{curPageData.dataStructure.rawName[1]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        
                    </div>
                </div>
                <YouWereEditing
                    isOpen={blockPopup}
                    onRequestClose={e => { e.preventDefault(); blocker.reset(); }}
                    onSubmit={e => { e.preventDefault(); blocker.proceed(); }}
                />
                <ChkDelModal
                    isOpen={chkDelModalIsOpen}
                    onRequestClose={() => setChkDelModalIsOpen(false)}
                    onSubmit={onDeleteData}
                />
            </React.Fragment>
    );
}

function SumDataInfo({ curPageMonitData, sumDsId }) {
    console.log("curPageMonitData", curPageMonitData);
    console.log("sumDsId", sumDsId);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post(`${API_URL}/monitoring-manage/getSumData`, { sumDsId: sumDsId, dataId: curPageMonitData["sumDataId"] });
                setData(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        fetchData();
    }, [curPageMonitData, sumDsId]);

    if (!data) {
        return <div>No data</div>;
    }
    console.log("data", data);
    console.log("data", data.data);
    // 데이터 없으면 밑에 세 상수 null처리 
    if(data.data === null){
        return (
            <div className='sumDataInfoArea'>
                <p>
                    <span>카운트 : </span>
                    <span>{curPageMonitData["serialNo"] ?? '　-　'}</span>
                </p>
                <p>
                    <span>차종 : </span>
                    <span>{'　-　'}</span>
                </p>
                <p>
                    <span>품명 : </span>
                    <span>{'　-　'}</span>
                </p>
                <p>
                    <span>품번 : </span>
                    <span>{'　-　'}</span>
                </p>
            </div>
        );
    } else{
        const { modelType, modelName, modelNo } = data.data.model;

        return (
            <div className='sumDataInfoArea'>
                <p>
                    <span>카운트 : </span>
                    <span>{curPageMonitData["serialNo"] ?? '　-　'}</span>
                </p>
                <p>
                    <span>차종 : </span>
                    <span>{modelType ?? '　-　'}</span>
                </p>
                <p>
                    <span>품명 : </span>
                    <span>{modelName ?? '　-　'}</span>
                </p>
                <p>
                    <span>품번 : </span>
                    <span>{modelNo ?? '　-　'}</span>
                </p>
            </div>
        );
    }
    
};

function ChartInfo({ data, dataStructure }) {
    console.log("그래프 정보 렌더링 시작: " + new Date().toTimeString().split(' ')[0] + "." + new Date().getMilliseconds());
    const convertedData = data.data;
    const data1Arr = convertedData.map(el => parseFloat(el.data1));
    const data2Arr = convertedData.map(el => parseFloat(el.data2 ?? 0));
    const max1 = Math.max(...data1Arr);
    const max2 = Math.max(...data2Arr);
    const dateData = data.date;
    const year = parseInt(dateData.substr(0, 4));
    const month = parseInt(dateData.substr(4, 2));
    const date = parseInt(dateData.substr(6, 2));
    const hours = parseInt(dateData.substr(9, 2));
    const minutes = parseInt(dateData.substr(11, 2));
    const seconds = parseInt(dateData.substr(13, 2));
    const ampm = hours < 12 ? '오전' : '오후';

    if (dataStructure.name !== "하중센서") {
        console.log("그래프 정보 렌더링 종료: " + new Date().toTimeString().split(' ')[0] + "." + new Date().getMilliseconds());
        return (<div className='chartInfoArea'>
            <p>
                <span>
                    {"생산 일시 : "}
                </span>
                <span>
                    {`${year}년 ${month}월 ${date}일 ${ampm} ${hours > 12 ? hours - 12 : hours}시 ${minutes}분 ${seconds}초`}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.rawName[0]} : `}
                </span>
                <span>
                    {max1.toFixed(2)}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.rawName[1]} : `}
                </span>
                <span>
                    {max2.toFixed(2)}
                </span>
            </p>
        </div>);
    } else {
        console.log("그래프 정보 렌더링 종료: " + new Date().toTimeString().split(' ')[0] + "." + new Date().getMilliseconds());
        return (<div className='chartInfoArea'>
            <p>
                <span>
                    {"생산 일시 : "}
                </span>
                <span>
                    {`${year}년 ${month}월 ${date}일 ${ampm} ${hours > 12 ? hours - 12 : hours}시 ${minutes}분 ${seconds}초`}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.name[0]} : `}
                </span>
                <span>
                    {max1.toFixed(2)}
                </span>
            </p>
            <p>
                <span>
                    {`이상패턴 : `}
                </span>
                <span>
                    {data["sumData"]["abnormalPattern"] ?? '-'}
                </span>
            </p>
        </div>);
    }

}

export default RealTimeMonitoring;